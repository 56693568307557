@import '~antd/dist/antd.css';
.App {
  font-family: "Nunito Sans", sans-serif;
 
}
.header {
  background: transparent;
  margin: 20px auto 0;
}
table {
  background-color: #fff;
}
th {
  background-color: #fff !important;
}
tbody > tr > td {
  border: none;
}
tr:nth-child(even) {
  background-color: #eff9f7;
}
table, th, td  {
  border-left: 0 !important;
  border-right: 0 !important;
  color: #6C757D !important;
  border-top: 0 !important;
}

th {
  border-top: 0 !important;
}


.table-container {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important
}

.dashboard-logo {
  padding: 10px;
  text-align: center;
}


.upload-button {
    display: 'flex'; 
    flex-direction: 'row';
    width: 256px;
    height: 256px; 
    justify-content: 'center'; 
    align-items: 'center';
    border: 1px dashed #000;
}


.main-auth {
  background-image: url("https://res.cloudinary.com/ddjyel5tz/image/upload/c_scale,w_1280/v1566845764/dan-meyers-IQVFVH0ajag-unsplash_uk2rsw.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
}
 .login-form {
  width: 100%;
}
 .login-form-forgot {
  float: left;
  color: #009688;
}
 .login-form-button {
  width: 100%;
}
.top-bar {
  display: flex;
  justify-content: space-between;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}
.mr-15 {
  cursor: pointer;
  margin: 0 10px;
  font-weight: 600;
}
.add-form {
  max-width: 550px;
  margin: 0 auto;
  background: #fff;
  padding: 10px 15px;
}
.edit-form {
  max-width: 500px;
  background: #fff;
  padding: 10px 15px;
}

.spin {
  text-align: center;
  vertical-align: middle;
}

.expand {
  display: none;
}

.payButton {
  display: none;
}

.hazie-img {
  width: 70%;

}

.responsive-img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.farm-container {
  background-color: white;
}

@media (max-width: 599px) {
  .mr-15 {
    display: none;
  }
}
